<template>
  <v-container fluid class="pa-0">
    <v-row>
      <img src="../assets/products.png" height="100%" width="100%">
    </v-row>
    <v-row>
      <v-col
        cols="3"
        class="d-none d-md-block"
      >
        <ProductsMenu class="mx-4 my-4"></ProductsMenu>
        <ContactUs class="mx-4 mb-4"></ContactUs>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="pl-4 pr-6"
      >
        <h3 class="blue--text text--darken-2 py-2">{{ types[$route.params.id] }}</h3>
        <v-divider></v-divider>
        <v-data-iterator
          :items="currentProducts"
          :items-per-page.sync="itemsPerPage"
          :page="page"
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="product in props.items"
                :key="product.id"
                class="d-flex child-flex"
                cols="6"
                md="4"
              >
                <v-card hover :to="`/productdetail/${product.id}`">
                  <v-img contain :aspect-ratio="4/3" :src="require(`@/assets/img/${product.img}`)" class="grey lighten-4" />
                  <v-card-text class="text-center subtitle-1 py-2">Shenmamid&reg; {{ product.name }}</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                      <v-icon>mdi-page-next-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-row
              class="mt-2"
              align="center"
              justify="center"
            >
              <span
                class="mr-4
                grey--text"
              >
                Page {{ page }} of {{ numberOfPages }}
              </span>
              <v-btn
                small
                outlined
                dark
                color="blue darken-3"
                class="mr-1"
                @click="formerPage"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                small
                outlined
                dark
                color="blue darken-3"
                class="ml-1"
                @click="nextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductsMenu from './ProductsMenu'
import ContactUs from './ContactUs'

import productlist from '@/assets/json/productlist.json'

export default {
  components: {
    ProductsMenu,
    ContactUs
  },

  data: () => ({
    currentType: null,
    types: ['All Products', 'Generic Products', 'Enhanced Products', 'Toughened Products', 'Flame Retardant Products', 'Enhanced and Toughened Products'],
    currentProducts: {},
    products: productlist.products,
    page: 1,
  }),

  computed: {
    numberOfPages () {
      return Math.ceil(this.currentProducts.length / this.itemsPerPage)
    },

    itemsPerPage () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 6
        case 'sm': return 6
        default: return 9
      }
    }
  },

  created () {
    this.fetchData()
  },

  watch: {
    '$route': 'fetchData'
  },

  methods: {
    fetchData () {
      var type = this.$route.params.id
      if ( type > 0 )
      {
        this.currentProducts = this.products.filter(function(item){
          return item.type == type
        })
      }
      else
      {
        this.currentProducts = this.products
      }
      this.currentType = type
    },

    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },

    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
  },
}
</script>
